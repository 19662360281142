import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import classes from "./EventTicketDetails.module.css";
import TicketCountComponenet from "./TicketCountComponenet";

const EventTicketDetails = ({
  packageName,
  eventId,
  unitPrice,
  setTotal,
  packageId,
  status,
}) => {
  const [ticketCount, setTicketCount] = useState(0);

  useEffect(() => {
    const cart = JSON.parse(sessionStorage.getItem("cart")) || null;

    if (cart && packageId && eventId) {
      if (cart.eventId === eventId) {
        const ticket = cart.tickets.find(
          (item) => item.packageId === packageId
        );

        if (ticket) {
          setTicketCount(parseInt(ticket.ticketCount));
        }
      }
    }
  }, [packageId, eventId]);

  return (
    <div className={classes.ticketDetailsContainer}>
      <div className={`d-flex ${classes.packageTitleContainer}`}>
        <h6 className={classes.packageTitle}>{packageName}</h6>
      </div>
      <div>
        <Row>
          <Col>
            <p className={classes.packageSubTitle}>ticket price</p>
            <p className={classes.ticketPrice}>LKR.{unitPrice}</p>
          </Col>
          <Col>
            <p className={classes.packageSubTitle}>quantity</p>
            {status === "on sale" ? (
              <TicketCountComponenet
                ticketCount={ticketCount}
                setTicketCount={setTicketCount}
                setTotal={setTotal}
                unitPrice={unitPrice}
                packageId={packageId}
              />
            ) : status === "sold out" ? (
              <p className={classes.packageNotAvailableText}>Sold Out</p>
            ) : status === "pending" ? (
              <p className={classes.packageNotAvailableText}>Coming Soon</p>
            ) : null}
          </Col>

          <Col>
            {status === "on sale" && (
              <>
                <p className={classes.packageSubTitle}>sub total</p>
                <p className={classes.ticketPrice}>
                  {ticketCount === 0
                    ? "--"
                    : Intl.NumberFormat("en-LK", {
                        style: "currency",
                        currency: "LKR",
                      }).format(unitPrice * ticketCount)}
                </p>
              </>
            )}
          </Col>
        </Row>
      </div>
      <div className={classes.packageDetailsDivider}></div>
    </div>
  );
};

export default EventTicketDetails;
