import classes from "./HomePage.module.css";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import { useContext } from "react";
import { contactContext } from "../App";
import businessLogo from "../images/eventgooze-name-&-goose-logo.webp";
import PrimaryActionButton from "./PrimaryActionButton";

const HomePage = () => {
  const { contactModalShow, setContactModalShow, setContactUsDefaultMessage } =
    useContext(contactContext);
  const { setDoucmentTitle } = useDocumentTitle(
    `Eventgooze | Live The Moment.`
  );
  const navigate = useNavigate();

  return (
    <>
      <section className={classes.homePageMainSection}>
        <Container>
          <Row
            className={`justify-content-center align-items-center ${classes.MenuContainer}`}
          >
            <div className={classes.businessDetailsContainer}>
              <div className={classes.businessLogoContainer}>
                <img
                  src={businessLogo}
                  alt="business logo"
                  className={classes.businessLogo}
                />
              </div>
              <div className={classes.businessDetails}>
                <p className={classes.businessSlogan}>live the moment.</p>
              </div>
            </div>
            <PrimaryActionButton
              className={classes.goToEventsButton}
              buttonText={"start your journey"}
              onClick={() => {
                navigate("/events");
              }}
            />
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomePage;
