import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./NavigationBar.module.css";
import NavbarBusinessLogo from "./NavbarBusinessLogo";
import { contactContext } from "../App";
import { Link } from "react-scroll";

const NavigationBar = () => {
  const { contactModalShow, setContactModalShow, setContactUsDefaultMessage } =
    useContext(contactContext);
  const { pathname } = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [pathname]);

  const showContactModal = () => {
    setContactUsDefaultMessage("");
    setContactModalShow(true);
  };

  return (
    !isHomePage && (
      <section className={classes.navBarContainer}>
        <Container className="customContainer">
          <Row className="justify-content-between">
            <Col xs="auto" className="d-flex justify-content-center">
              <NavbarBusinessLogo />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <Row>
                <Col xs="auto">
                  <div className={classes.navBarLinkContainerParent}>
                    <Row className="">
                      <Col xs="auto">
                        <NavLink
                          end
                          style={{ textDecoration: "none" }}
                          to={`/`}
                          className={({ isActive }) =>
                            isActive ? `${classes.activeTab}` : ""
                          }
                        >
                          <p className={classes.navLinkText}>home</p>
                        </NavLink>
                      </Col>
                      <Col
                        xs="auto"
                        className="d-sm-block d-block"
                        style={{ padding: "0 3px" }}
                      >
                        <div className={classes.navLinkDivider}></div>
                      </Col>
                      <Col xs="auto">
                        <NavLink
                          end
                          style={{ textDecoration: "none" }}
                          to={`/events`}
                          className={({ isActive }) =>
                            isActive ? `${classes.activeTab}` : ""
                          }
                        >
                          <p className={classes.navLinkText}>all events</p>
                        </NavLink>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className={classes.navbarDivider}></div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  );
};

export default NavigationBar;
