import changeDateFormat from "../utils/changeDateFormat";
import TicketMetaDataContainer from "./TicketMetaDataContainer";
import classes from "./TicketTimeVenueContainer.module.css";

const TicketTimeVenueContainer = ({
  startDateAndTime,
  endDateAndTime,
  location,
}) => {
  return (
    <div className={`${classes.ticketTimeVenueContainer}`}>
      <TicketMetaDataContainer
        label="date"
        icon="calendar_month"
        data={changeDateFormat(startDateAndTime)}
      />
      <div className={classes.eventDetailsDivider}></div>
      <TicketMetaDataContainer
        label="time"
        icon="schedule"
        data={`${startDateAndTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })} - ${endDateAndTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`}
      />
      <div className={classes.eventDetailsDivider}></div>
      <TicketMetaDataContainer label="venue" icon="home_pin" data={location} />
      <div className={`d-none ${classes.eventDetailsDivider}`}></div>
    </div>
  );
};

export default TicketTimeVenueContainer;
