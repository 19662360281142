import "./App.css";
import { createContext, useState } from "react";

// third party libraries
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// custom components
import BaseLayout from "./components/BaseLayout";
import EventDetails, { eventAction } from "./components/EventDetails";
import CheckoutPage, { checkoutLoader } from "./components/CheckoutPage";
import { eventLoader } from "./components/EventDetails";
import useHttp from "./custom-hooks/useHttp";
import CompleteOrderPage, { orderLoader } from "./components/CompleteOrderPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import RefundPolicy from "./components/RefundPolicy";
import HomePage from "./components/HomePage";
import { CheckoutPageActions } from "./Actions/CheckoutPageActions";
import TicketScanPage, { LoginAction } from "./components/TicketScanPage";
import CheckInPage, {
  checkInAction,
  ticketLoader,
} from "./components/CheckInPage";
import AllEventsPage, { eventsListLoader } from "./components/AllEventsPage";

// contexts
export const contactContext = createContext();

function App() {
  // custom hooks
  const { sendRequest } = useHttp();

  const [contactModalShow, setContactModalShow] = useState(false);
  const [contactUsDefaultMessage, setContactUsDefaultMessage] = useState("");

  const router = createBrowserRouter([
    {
      path: "/scan-tickets",
      element: <TicketScanPage />,
      action: LoginAction(sendRequest),
    },
    {
      path: "/check-in/:ticket_token",
      element: <CheckInPage />,
      loader: ticketLoader(sendRequest),
      action: checkInAction(sendRequest),
    },
    {
      path: "/",
      element: (
        <contactContext.Provider
          value={{
            contactModalShow,
            setContactModalShow,
            contactUsDefaultMessage,
            setContactUsDefaultMessage,
          }}
        >
          <BaseLayout />
        </contactContext.Provider>
      ),
      children: [
        {
          index: true,
          element: <HomePage />,
        },
        {
          path: "events",
          element: <AllEventsPage />,
          loader: eventsListLoader(sendRequest),
        },
        {
          path: "events/:id/:slug",
          element: <EventDetails />,
          loader: eventLoader(sendRequest),
          action: eventAction(sendRequest),
        },
        {
          path: "checkout/:order_id",
          element: <CheckoutPage />,
          loader: checkoutLoader(sendRequest),
          action: CheckoutPageActions(sendRequest),
        },
        {
          path: "/complete-order/:order_id",
          element: <CompleteOrderPage />,
          loader: orderLoader(sendRequest),
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "/refund-policy",
          element: <RefundPolicy />,
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
